module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stephen Zuniga","short_name":"Stephen Zuniga","start_url":"/","background_color":"#26323C","theme_color":"#F98B68","display":"standalone","icon":"src/assets/z-block.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"537a1c87d38cc9b385ac37d5ed221c59"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/buildhome/repo/src/layouts/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/buildhome/repo/src/templates/MarkdownTemplate.tsx"},"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","JSFrontmatterEngine":false,"engines":{}},
    }]
